'use strict';

(function() {
    $('img[data-src]').each(function() {
        var $this = $(this);
        var src = $this.data('src');
        var newSrc = src.replace('[faker-initials]', faker.name.initials());
        $this.attr('data-src', newSrc);
    });
    
    $('.your-checkbox').prop('indeterminate', true);

    // Init Bootstrap Tooltips
    $('[data-toggle="tooltip"]').tooltip();

    // Init Bootstrap Popovers
    $('[data-toggle="popover"]').popover();
})();